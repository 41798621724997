.more {
  background: #8321FC;
  
  .container {
    @include vp-767 {
      max-width: 100vw;
      overflow-x: scroll;
    }
  }
}
.more__item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 0;
  color: #FAFAFA;
  @include adaptiveDesktopToMobile('font-size', 24, 18);
  font-weight: 900;
  letter-spacing: -0.24px;
  text-transform: uppercase;
  white-space: nowrap;

  @include vp-767 {
    justify-content: flex-start;
    width: fit-content;
    overflow: scroll;
  }
}
.more__item-pic {
  margin: 0 13px;
}
.more__item-time {
  opacity: 0.7;
  text-transform: unset;
  margin-left: 13px;
}