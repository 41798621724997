.form {
  max-width: 620px;
  margin: 0 auto;
}
.form__input-wrapper {
  width: 100%;
  position: relative;

  &.is-invalid {
    &::before {
      content: url(../img/svg/error.svg);
      position: absolute;
      @include adaptiveDesktopToMobile('bottom', 36, 33);
      right: 12px;
      display: block;
      width: 26px;
      height: 26px;
      z-index: 1;
    }
    .form__input-label-text {
      color: var(--pink, #FD4584);
    }
    .form__input {
      border-color: var(--pink, #FD4584);
    }
    .input-message {
      &.is-invalid {
        color: var(--pink, #FD4584);
      }
    }
  }

  &:not(:last-child) {
    margin-bottom: 30px;
  }
}
.form__input-label {
}
.form__input-label-text {
  color: var(--light-grey, #828282);
  @include adaptiveDesktopToMobile('font-size', 20, 14);
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.2px;
  margin-bottom: 4px;
}
.form__input {
  width: 100%;
  border-radius: 10px;
  border: 1px solid var(--light-grey, #828282);
  background: #FFF;
  outline: none;
  padding: 12px;
  @include adaptiveDesktopToMobile('font-size', 20, 14);
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.2px;
  transition: $trans-default;

  @include hover-focus {
    border-color: var(--yellow, #F9D606);
  }
}
.form__input-wrapper--password {
}
.form__input-see-password {
  position: absolute;
  right: 12px;
  @include adaptiveDesktopToMobile('bottom', 20, 14);
}
.form__input-see-password-icon {
}
.form__checkbox {
  &.is-invalid {
    label {
      color: var(--pink, #FD4584);

      &::before {
        border-color: var(--pink, #FD4584);
      }
    }
  }
}
.form__submit {
  border-radius: 12px;
  background: var(--pink, #FD4584);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #FFF;
  font-size: 17px;
  font-weight: 900;
  letter-spacing: 0.17px;
  text-transform: uppercase;
  padding: 17px 30px;
  transition: $trans-default;

  @include hover-focus {
    background: var(--yellow, #F9D606);
  }
}
.form__warning {
  color: #828282;
  font-size: 12px;
  line-height: normal;
  letter-spacing: -0.14px;
  padding-left: 30px;

  @include vp-767 {
    padding: 0 30px;
    text-align: center;
  }
}














.custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-checkbox+label {
  display: inline-flex;
  align-items: center;
  user-select: none;
  color: var(--light-grey, #828282);
  @include adaptiveDesktopToMobile('font-size', 24, 18);
  letter-spacing: -0.24px;
}
.custom-checkbox+label::before {
  content: '';
  display: inline-block;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #adb5bd;
  border-radius: 0.25em;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}
.custom-checkbox:checked+label::before {
  border-color: #F9D606;
  background-color: #F9D606;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23000' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}