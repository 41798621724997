.btn {
  display: inline-flex;
  padding: 10px 20px;

  color: $color-default-white;

  background-color: $color-neon-blue;
  border: none;
  cursor: pointer;

  transition: opacity $trans-default;

  &--red {
    background-color: $color-torch-red;
  }

  &:disabled {
    opacity: 0.5;

    pointer-events: none;
  }

  @include hover-focus {
    opacity: 0.8;
  }
}
