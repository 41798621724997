.error {
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
.error__pic {
  @include adaptiveDesktopToMobile('width', 325, 168);
}
.error__title {
  color: var(--pink, #FD4584);
  text-align: center;
  @include adaptiveDesktopToMobile('font-size', 204, 50);
  font-weight: 900;
  line-height: 1;
  letter-spacing: -2.039px;
  margin: 0;
}
.error__subtitle {
  @include adaptiveDesktopToMobile('font-size', 24, 18);
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
}
.error__more {
  text-align: center;
  @include adaptiveDesktopToMobile('font-size', 24, 18);
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
  max-width: 270px;

  a {
    text-decoration: underline;
  }
}