.videos {
  @include adaptiveDesktopToMobile('padding-top', 50, 20);
  @include adaptiveDesktopToMobile('margin-top', 70, 0);
  background-color: #F5F5F5;

  .container {
    display: grid;
    grid-template-columns: 1.25fr 1fr;
    gap: 30px;
  }

  @include vp-1023 {
    .container {
      grid-template-columns: auto;
    }
  }
}
.videos__item {
}
.videos__item-title {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.videos__item-title-icon {
  margin-left: 15px;
  @include adaptiveDesktopToMobile('width', 47, 37);
}
.videos__item-poster {
  @include vp-1023 {
    @include adaptiveDesktopToMobile('margin-left', -165, -18);
    @include adaptiveDesktopToMobile('margin-right', -165, -18);
    width: 100vw;
    max-width: unset;
  }
}
.videos__item-video {
  @include vp-1023 {
    .video__preview {
      @include adaptiveDesktopToMobile('margin-left', -165, -18);
      @include adaptiveDesktopToMobile('margin-right', -165, -18);
      width: 100vw;
      max-width: unset;
    } 
  }
}