.all-shows {
  @include adaptiveDesktopToMobile('padding-bottom', 100, 50);
}
.all-shows__title {
  color: $color-pink;
  text-align: center;
  @include adaptiveDesktopToMobile('font-size', 35, 24);
  font-weight: 900;
  letter-spacing: -0.35px;
  @include adaptiveDesktopToMobile('margin-top', 50, 25);
  @include adaptiveDesktopToMobile('margin-bottom', 50, 25);
}
.all-shows__subtitle {
  color: #828282;
  text-align: center;
  @include adaptiveDesktopToMobile('font-size', 18, 14);
  letter-spacing: -0.18px;
  max-width: 730px;
  margin: 0 auto;
}
.all-shows__list {
  @include reset-list;
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  column-gap: 43px;
  row-gap: 80px;

  @include vp-1279 {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 28px;
    row-gap: 40px;
  }

  @include vp-767 {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 12px;
  }
}
.all-shows__item {
}
.all-shows__item-link {
}
.all-shows__item-poster {
}
.all-shows__item-raiting {
  color: #828282;
  @include adaptiveDesktopToMobile('font-size', 14, 13);
  font-weight: 700;
  letter-spacing: -0.14px;
  margin: 10px 0;
}
.all-shows__item-raiting-numbers {
}
.all-shows__item-raiting-controls {
}