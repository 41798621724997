.banner {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 1920px;
  margin: 0 auto;
}
.banner__bg {
  width: 90%;
  @include adaptiveDesktopToMobile('margin-left', 100, 0);

  @include vp-1279 {
    display: none;
  }
}
.banner__video {
  position: absolute;
  @include adaptiveDesktopToMobile('top', 110, 0);
  left: 50.5%;
  transform: translateX(-50%);
  @include adaptiveDesktopToMobile('width', 706, 95);
  @include adaptiveDesktopToMobile('height', 393, 52);

  @include vp-1279 {
    position: static;
    transform: unset;
    width: 100%;
    height: auto;
  }
}
.banner__social {
  margin: 0 auto;
  display: flex;
  justify-content: center;

  @include vp-1279 {
    display: none;
  }
}
.banner__social-link {
  transition: $trans-default;
  
  &:not(:last-child) {
    margin-right: 20px;
  }

  @include hover-focus {
    color: $color-pink;
  }
}