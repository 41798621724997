.auth {
  @include adaptiveDesktopToMobile('padding-top', 50, 25);
}
.auth__title {
  color: var(--pink, #FD4584);
  text-align: center;
  @include adaptiveDesktopToMobile('font-size', 35, 20);
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.35px;
  @include adaptiveDesktopToMobile('margin-bottom', 50, 30);
  margin-top: 0;
}
.auth__form {
  width: 100%;
}
.auth__form-links {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  @include vp-767 {
    flex-direction: column;
  }
}
.auth__form-link {
  color: var(--dark-gray, #333);
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.18px;
  text-decoration-line: underline;

  @include vp-767 {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}
.auth__form-footer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;

  @include vp-767 {
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
  }
}
.auth__vk {
  margin: 100px auto;
  width: fit-content;
  display: flex;
  align-items: center;
  color: var(--dark-gray, #333);
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.18px;

  @include vp-767 {
    margin: 35px auto;
  }
}
.auth__vk-icon {
  margin-left: 16px;
}