.video {
  position: relative;
  display: block;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-image: url('../img/content/play.svg');
    background-size: cover;
    @include adaptiveDesktopToMobile('width', 100, 52);
    @include adaptiveDesktopToMobile('height', 101, 50);
  }
}
.video__preview {
  width: 100%;
}