.shows {
  @include adaptiveDesktopToMobile('padding-top', 70, 30);
  @include adaptiveDesktopToMobile('padding-bottom', 95, 30);
  background-color: #F5F5F5;
}
.shows__title {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.shows__title-icon {
  margin-left: 15px;
}
.shows__slider {
  .swiper-navigation__btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    transition: $trans-default;
    opacity: 0;

    &--prev {
      left: -100px;
    }

    &--next {
      right: -100px;
    }
  }

  @include hover-focus {
    .swiper-navigation__btn {
      opacity: 1;

      &--prev {
        left: 20px;
      }
  
      &--next {
        right: 20px;
      }
    }
  }

  @include vp-767 {
    // .swiper-wrapper {
    //   display: grid;
    //   grid-template-columns: repeat(2, 1fr);
    //   gap: 16px;
    // }

    .swiper-navigation__btn {
      opacity: 1;

      &--prev {
        left: 0px;
      }
  
      &--next {
        right: -5px;
      }
    }
  }
}
.shows__slide {
  overflow: hidden;

  // @include vp-767 {
  //   &:nth-child(n + 5) {
  //     display: none;
  //   }
  // }
}
.shows__slide-img {
  transition: $trans-default;

  @include hover-focus {
    transform: scale(1.1);
  }
}