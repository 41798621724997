.search {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.80);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: $trans-default;
  opacity: 0;
  pointer-events: none;

  &.is-active {
    opacity: 1;
    pointer-events: all;
  }
}
.search__content {
  width: 90%;
  max-width: 490px;
}
.search__header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.search__title {
  color: var(--wite, #FAFAFA);
  font-size: 30px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.3px;
  margin: 0;
  margin-right: 30px;
}
.search__close {
}
.search__close-icon {
}
.search__input-wrapper {
  border-radius: 30px;
  border: 3px solid var(--pink, #FD4584);
  background: #FFF;
  display: flex;
  align-items: center;
  padding: 14px;
  margin-bottom: 20px;
}
.search__icon {
  margin-right: 10px;
}
.search__input {
  border: unset;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.2px;
  outline: none;

  &::placeholder {
    color: var(--light-grey, #828282);
  }
}
.search__variants {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: -5px;
}
.search__variant {
  border-radius: 10px;
  background: var(--pink-gradient, linear-gradient(102deg, #F54784 0%, #F6387C 43.67%, #B72158 100%));
  color: #FFF;
  font-size: 14px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.14px;
  text-transform: uppercase;
  padding: 5px 10px;
  margin: 5px;
}