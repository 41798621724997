// @font-face {
//   font-style: normal;
//   font-weight: 400;
//   font-family: "Rouble";

//   font-display: swap;
//   src:
//     url("../fonts/rouble.woff2") format("woff2"),
//     url("../fonts/rouble.woff") format("woff");
// }

@font-face {
  font-style: normal;
  font-weight: 400;
  font-family: "";

  font-display: swap;
  src:
    url("../fonts/.woff2") format("woff2"),
    url("../fonts/.woff") format("woff");
}
