.voting-results {
  padding-bottom: 100px;
}
.voting-results__title {
  color: var(--pink, #FD4584);
  text-align: center;
  @include adaptiveDesktopToMobile('font-size', 35, 20);
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.35px;
}
.voting-results__subtitle {
  color: #8C8C8C;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.14px;

  @include vp-767 {
    display: none;
  }

}
.voting-results__description {
  color: #000;
  text-align: center;
  @include adaptiveDesktopToMobile('font-size', 24, 16);
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
  max-width: 810px;
  margin: 0 auto 100px;
  @include adaptiveDesktopToMobile('margin-bottom', 100, 50);
}
.voting-results__list {
  @include reset-list;
  max-width: 1260px;
  margin: 0 auto;
}
.voting-results__item {
  display: grid;
  grid-template-columns: 78px 3fr 1fr 2fr;
  gap: 20px;

  &:not(:last-child) {
    margin-bottom: 30px;
  }

  @include vp-767 {
    grid-template-columns: 61px auto;
    gap: 10px;
  }
}
.voting-results__item-pic {
}
.voting-results__item-name {
  color: var(--pink, #FD4584);
  @include adaptiveDesktopToMobile('font-size', 24, 14);
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.24px;
}
.voting-results__item-place {
  color: var(--purple, #8321FC);
  @include adaptiveDesktopToMobile('font-size', 24, 14);
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.24px;
}
.voting-results__progressbar {
  display: flex;
}
.voting-results__progressbar-line {
  @include adaptiveDesktopToMobile('width', 244, 167);
  height: 15px;
  border-radius: 10px;
  background: var(--light-blue-background, #F2F2F2);
  @include adaptiveDesktopToMobile('margin-top', 5, 3);
  overflow: hidden;
  position: relative;

  span {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 10px;
    background: var(--pink, #FD4584);
    height: 100%;
  }
}
.voting-results__progressbar-counter {
  margin-left: 10px;
  color: #8C8C8C;
  @include adaptiveDesktopToMobile('font-size', 20, 14);
  font-weight: 400;
  line-height: normal;
}