.voting {
}
.voting__title {
  color: var(--pink, #FD4584);
  text-align: center;
  @include adaptiveDesktopToMobile('font-size', 35, 20);
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.35px;
}
.voting__subtitle {
  color: #8C8C8C;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.14px;

  @include vp-767 {
    display: none;
  }
}
.voting__descripton {
  color: #000;
  text-align: center;
  @include adaptiveDesktopToMobile('font-size', 24, 16);
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
  max-width: 810px;
  margin: 0 auto 100px;
  @include adaptiveDesktopToMobile('margin-bottom', 100, 50);
}
.voting__form {
  max-width: 1260px;
  margin: 0 auto;
}
.voting__list {
  @include reset-list;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 60px 26px;

  @include vp-1023 {
    grid-template-columns: repeat(3, 1fr);
    gap: 50px 24px;
  }
  @include vp-767 {
    grid-template-columns: repeat(2, 1fr);
    gap: 20px 10px;
  }
}
.voting__item {
  display: flex;
  flex-direction: column;
}
.voting__item-pic {
  border-radius: 5px;
}
.voting__item-name {
  color: #FD4584;
  @include adaptiveDesktopToMobile('font-size', 20, 14);
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.2px;
  margin: 10px 0 20px;
  @include adaptiveDesktopToMobile('margin-top', 10, 5);
  @include adaptiveDesktopToMobile('margin-bottom', 20, 10);
}
.voting__item-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;

  & + label {
    display: inline-flex;
    align-items: center;
    user-select: none;
    border-radius: 12px;
    border: 2px solid #F54784;
    width: 100%;
    align-items: center;
    justify-content: center;
    padding: 8px;
    color: var(--dark-gray, #333);
    text-align: center;
    @include adaptiveDesktopToMobile('font-size', 17, 14);
    font-weight: 900;
    line-height: normal;
    letter-spacing: 0.17px;
    text-transform: uppercase;
    cursor: pointer;
    transition: $trans-default;
    margin-top: auto;

    &:hover {
      background: var(--pink, #FD4584);
      color: #FFFFFF;
    }
  }
  
  &:checked {
    & + label {
      display: inline-flex;
      align-items: center;
      user-select: none;
      background: var(--pink, #FD4584);
      color: #FFFFFF;
    }
  }

  &:disabled {
    & + label {
      border: 2px solid var(--light-blue-background, #F2F2F2);
      color: var(--light-blue-background, #F2F2F2);
    }
  }
}
.voting__form-description {
  text-align: center;
  @include adaptiveDesktopToMobile('font-size', 24, 18);
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
  margin-top: 60px;
}
.voting__form-submit {
  border-radius: 12px;
  color: #FFF;
  text-align: center;
  font-size: 17px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.17px;
  text-transform: uppercase;
  padding: 15px 30px;
  margin: 20px auto 60px;
  display: flex;
  background: var(--pink, #FD4584);

  &:disabled {
    background: var(--light-blue-background, #F2F2F2);
  }

  @include vp-767 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}