.footer {
  background-color: #F5F5F7;
  padding: 40px 0;
  color: #828282;
  font-size: 15px;
  letter-spacing: -0.15px;

  .container {
    display: flex;
    justify-content: space-between;
  }

  @include vp-1023 {
    padding: 24px 0 32px;
    font-size: 12px;
  }

  @include vp-767 {
    .container {
      flex-direction: column;
    }
  }
}
.footer__column {
  display: flex;
  
  @include vp-767 {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
.footer__logo {
  margin-right: 30px;

  @include vp-767 {
    margin: 0 0 18px;
  }
}
.footer__logo-img {
}
.footer__text {
  max-width: 420px;
}
.footer__info {

}
.footer__links {
  margin-top: 30px;

  @include vp-767 {
    margin-top: 15px;
  }
}
.footer__link {
  font-size: 12px;
  color: inherit;

  &:not(:last-child) {
    margin-right: 40px;
  }

  @include vp-767 {
    display: block;

    &:not(:last-child) {
      margin-right: 0;
    }
  }
}
.footer__social {
  @include vp-767 {
    margin-top: 15px;
  }
}
.footer__social-title {
  margin-bottom: 7px;

  @include vp-767 {
    display: none;
  }
}
.footer__social-links {
}
.footer__social-link {
  transition: $trans-default;
  opacity: 0.5;

  &:not(:last-child) {
    margin-right: 12px;
  }

  @include hover-focus {
    color: $color-pink;
  }

  @include vp-767 {
    display: inline-block;
    width: 34px;
    height: 34px;
  }
}