.header {
  z-index: 999;
  padding-top: 50px;
}
.container {
}
.header__top {
  position: fixed;
  top: 0;
  width: 100%;
  height: 55px;
  background: linear-gradient(180deg, #FDF932 0%, #F6DE19 47.11%, #EDC005 97.40%);
  z-index: 1000;
  
  .container {
    display: flex;
  }
}
.header__logo {
  margin-left: -50px;
  

  @include vp-767 {
    width: 122px;
    height: 64px;
  }
}
.header__logo-img {
}
.header__menu-btn {
  margin-left: auto;
  margin-top: 20px;
  display: flex;
  align-items: center;
  height: fit-content;
  margin-right: 18px;

  @include vp-767 {
    margin-right: 0;
  }
}
.header__menu-btn-text {
  font-size: 18px;
  font-weight: 900;
  margin-left: 8px;

  @include vp-767 {
    display: none;
  }
}
.header__menu-btn-icon {
}
.header__search-btn {
  margin-top: 10px;

  @include vp-767 {
    display: none;
  }
}
.header__search-btn-icon {
}
.header__main-menu {
  margin-top: 50px;

  @include vp-1279 {
    display: none;
  }
}
.header__main-menu-list {
  @include reset-list;
  display: flex;
  justify-content: center;
}
.header__main-menu-item {
  &:not(:last-child) {
    @include adaptiveDesktopToMobile('margin-right', 40, 0);
  }
}
.header__main-menu-link {
  @include adaptiveDesktopToMobile('font-size', 18, 13);
  font-weight: 700;
  transition: $trans-default;

  &--active {
    color: $color-pink;
  }

  @include hover-focus {
    color: $color-pink;
  }

  &--new {
    display: flex;
    align-items: center;

    &::after {
      content: url('../img/svg/new.svg');
      margin-left: 10px;
    }
  }
}
.header__second-menu {
  background-color: $color-wite;
  padding-top: 75px;
  position: fixed;
  top: 55px;
  left: 0;
  width: 100%;
  transition: $trans-default;
  opacity: 0;
  pointer-events: none;
  z-index: 999;

  &.is-active {
    opacity: 1;
    pointer-events: all;
  }

  .container {
    display: flex;
    justify-content: space-between;
  }

  @include vp-767 {
    padding-top: 30px;
    padding-bottom: 40px;

    .container {
      flex-direction: column;
    }

  }
}
.header__second-menu-info {
  max-width: 280px;
  color: #828282;
  font-size: 16px;
  letter-spacing: -0.16px;
  @include adaptiveDesktopToMobile('margin-right', 45, 0);

  a {
    color: $color-dark-gray;
    text-decoration: underline;
  }

  @include vp-1439 {
    display: none;
  }
}
.header__second-menu-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  @include adaptiveDesktopToMobile('padding-bottom', 70, 0);

  @include vp-767 {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    row-gap: 20px;
  }
}
.header__second-menu-column {
  @include vp-767 {
    &:nth-child(1) {
      order: 1;
    }
    &:nth-child(2) {
      order: 3;
    }
    &:nth-child(3) {
      order: 5;
    }
    &:nth-child(4) {
      order: 2;
      grid-row: span 6;
    }
    &:nth-child(5) {
      order: 6;
    }
    &:nth-child(6) {
      order: 7;
    }
  }
}
.header__second-menu-mobile-illustration {
  display: none;

  @include vp-767 {
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}
.header__second-menu-link {
  font-size: 18px;
  font-weight: 700;
  display: block;

  &--main {
    margin-bottom: 18px;
  }

  &--small {
    font-size: 16px;
    font-weight: 400;
  }

  &--gray {
    color: #828282;

    &:not(:last-child) {
      margin-bottom: 8px;
    }
  }

  &--with-margin-top {
    margin-top: 12px;
  }

  &--new {
    display: flex;
    align-items: center;

    &::after {
      content: url('../img/svg/new.svg');
      margin-left: 10px;
    }
  }
}
.header__second-menu-social-wrapper {
  &--mobile {
    display: none;
  }

  @include vp-767 {
    &--desktop {
      display: none;
    }
    &--mobile {
      display: block;
    }
  }
}
.header__second-menu-social {
  margin-top: 24px;
}
.header__second-menu-social-link {
  transition: $trans-default;
  &:not(:last-child) {
    margin-right: 12px;
  }

  @include hover-focus {
    color: $color-pink;
  }
}
.header__second-menu-logout {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 700;
  margin-top: 20px;
}
.header__second-menu-logout-icon {
  margin-left: 6px;
}
.header__second-menu-illustration {
  margin-top: auto;

  @include adaptiveDesktopToMobile('width', 392, 0);

  @include vp-767 {
    display: none;
  }
}

@include vp-767 {
  [data-accordion="button"] {
    display: flex;

    &::after {
      content: url('../img/svg/arrow.svg');
      margin-left: 10px;
      margin-top: -2px;
      transition: $trans-default;
    }

    .header__second-menu-column.is-active & {
      &::after {
        transform: rotate(180deg);
      }
    }
  }
}