.entertainment {

}
.entertainment__title {
  color: var(--pink, #FD4584);
  text-align: center;
  @include adaptiveDesktopToMobile('font-size', 35, 20);
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.35px;
}
.entertainment__subtitle {
  color: #8C8C8C;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.14px;

  @include vp-767 {
    display: none
  }
}
.entertainment__list {
  @include reset-list;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 50px;
  @include adaptiveDesktopToMobile('margin', -18, -10);
}
.entertainment__item {
  width: calc(100% / 3);
  display: flex;
  flex-direction: column;
  align-items: center;
  @include adaptiveDesktopToMobile('padding', 18, 10);
  
  &:nth-child(4) {
    display: none;
  }

  @include vp-767 {
    width: calc(100% / 2);

    &:nth-child(4) {
      display: block;
    }
  }
}
.entertainment__item-link {
}
.entertainment__item-pic {
}
.entertainment__item-name {
  text-align: center;
  margin-top: 18px;
  color: var(--dark-gray, #333);
  text-align: center;
  @include adaptiveDesktopToMobile('font-size', 30, 20);
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.3px;
}
.entertainment__advice {
  @include adaptiveDesktopToMobile('margin-top', 130, 45);
  background: #A4E1F5;
  @include adaptiveDesktopToMobile('padding-left', 165, 18);
  @include adaptiveDesktopToMobile('padding-right', 165, 18);
  @include adaptiveDesktopToMobile('margin-left', -165, -18);
  @include adaptiveDesktopToMobile('margin-right', -165, -18);
  display: flex;
  align-items: center;
  justify-content: center;
  @include adaptiveDesktopToMobile('padding-top', 44, 12);
  @include adaptiveDesktopToMobile('padding-bottom', 44, 12);

  &.is-active {
    .entertainment__advice-title {
      display: none;
    }
  }

  @include vp-767 {
    flex-direction: column;
  }
}
.entertainment__advice-active-block {
  display: none;

  &.is-active {
    display: block;
  }
}
.entertainment__advice-active-title {
  color: var(--dark-gray, #333);
  @include adaptiveDesktopToMobile('font-size', 30, 24);
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.3px;
}
.entertainment__advice-active-text {
  color: var(--dark-gray, #333);
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.18px;
}
.entertainment__advice-pic {
  @include adaptiveDesktopToMobile('width', 474, 210);
}
.entertainment__advice-text {
  max-width: 420px;
}
.entertainment__advice-title {
  color: var(--dark-gray, #333);
  @include adaptiveDesktopToMobile('font-size', 30, 24);
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.3px;

}
.entertainment__advice-btn {
  border-radius: 12px;
  background: var(--pink, #FD4584);
  color: #FFF;
  text-align: center;
  font-size: 17px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.17px;
  text-transform: uppercase;
  padding: 13px 30px;

  @include vp-767 {
    width: 100%;
    margin-bottom: 50px;
  }
}