.results {
  padding-bottom: 100px;
}
.results__title {
  color: var(--pink, #FD4584);
  text-align: center;
  @include adaptiveDesktopToMobile('font-size', 35, 20);
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.35px;
}
.results__header {
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
}
.results__input-label {
  color: var(--light-grey, #828282);
  @include adaptiveDesktopToMobile('font-size', 20, 14);
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.2px;
}
.results__header-wrapper {
  display: flex;
  align-items: center;
}
.results__input-wrapper {
  border-radius: 10px;
  border: 1px solid var(--light-grey, #828282);
  background: #FFF;
  padding: 13px;
  display: inline-flex;
  align-items: center;
  width: calc(100% - 10px - 48px);

  @include vp-767 {
    padding: 8px;
  }
}
.results__input-icon {
  margin-right: 10px;

  @include vp-767 {
    display: none;
  }
}
.results__input {
  border: unset;
  font-size: 20px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.2px;
  outline: none;

  &::placeholder {
    color: var(--light-grey, #828282);
  }
}
.results__submit {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: var(--pink, #FD4584);
  color: #FFF;
  text-align: center;
  font-size: 17px;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 0.17px;
  text-transform: uppercase;
  height: 57px;
  padding: 0 30px;
  margin-left: 10px;

  @include vp-767 {
    padding: 0;
    width: 48px;
    height: 48px;
  }
}
.results__submit-icon {
  display: none;

  @include vp-767 {
    display: block;
    width: 24px;
    height: 24px;
  }
}
.results__submit-text {
  @include vp-767 {
    display: none
  }
}
.results__list {
  @include reset-list;
  width: 100%;
  max-width: 1240px;
  margin: 0 auto;
  @include adaptiveDesktopToMobile('margin-top', 70, 30);
}
.results__item {
  display: grid;
  grid-template-columns: 156px auto;
  column-gap: 40px;
  &:not(:last-child) {
    margin-bottom: 30px;
  }

  @include vp-767 {
    grid-template-columns: 70px auto;
    gap: 10px;
  }
}
.results__item-pic {
  grid-row: span 3;

  @include vp-767 {
    grid-row: span 2;
  }
}
.results__item-title {
  color: var(--pink, #FD4584);
  @include adaptiveDesktopToMobile('font-size', 24, 18);
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.24px;
  margin: 0;
}
.results__item-link {
  color: var(--light-grey, #828282);
  @include adaptiveDesktopToMobile('font-size', 16, 14);
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.16px;
  text-decoration-line: underline;
}
.results__item-description {
  color: var(--dark-gray, #333);
  @include adaptiveDesktopToMobile('font-size', 18, 14);
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.18px;
  max-width: 730px;

  @include vp-767 {
    grid-column: span 2;
  }
}