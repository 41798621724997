.connection {
  padding-top: 40px;
  .container {
    display: flex;
    justify-content: space-between;
  }

  @include vp-767 {
    .container {
      flex-direction: column;
    }
  }
}
.connection__content {
}
.connection__title {
  display: flex;
  align-items: center;
  margin: 0;
  @include adaptiveDesktopToMobile('margin-bottom', 26, 10);
}
.connection__title-icon {
  margin-left: 16px;

  @include vp-767 {
    display: none
  }
}
.connection__subtitle {
  @include adaptiveDesktopToMobile('font-size', 24, 16);
  letter-spacing: -0.24px;
}
.connection__providers {
  margin-top: 50px;
  @include adaptiveDesktopToMobile('margin-bottom', 90, 20);
  @include adaptiveFull('width', 900, 600, 280);

  .swiper-wrapper {
    transition-timing-function: linear;
  }
}
.connection__provider {
}
.connection__provider-logo {
  @include adaptiveDesktopToMobile('height', 34, 21);
  filter: brightness(0);
  opacity: 0.5;
  transition: $trans-default;

  @include hover-focus {
    filter: unset;
    opacity: 1;
  }
}
.connection__illustration {
  height: fit-content;
  margin-top: auto;
  
  &--desktop {
    @include adaptiveDesktopToMobile('width', 668, 0)
  }
  &--mobile {
    display: none;
  }

  @include vp-767 {
    &--desktop {
      display: none;
    }
    &--mobile {
      display: block;
    }
  }
}