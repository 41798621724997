.show {
  @include adaptiveDesktopToMobile('padding-top', 90, 45);
  padding-bottom: 50px;
}
.show__wrapper {
  display: grid;
  grid-template-columns: 1.56fr 1fr;
  @include adaptiveDesktopToMobile('gap', 60, 30);

  @include vp-1279 {
    grid-template-columns: auto;
  }
}
.show__main {
}
.show__main-video {
}
.show__video-footer {
  margin-top: 30px;
}
.show__info {
  display: flex;
  align-items: center;

  @include vp-767 {
    flex-direction: column;
    align-items: flex-start;
  }
}
.show__info-title {
  font-size: 16px;
  font-weight: 900;
  letter-spacing: -0.16px;
  margin-right: 12px;
}
.show__raiting {
  display: flex;
  align-items: center;

  @include vp-1919 {
    flex-direction: column;
    align-items: flex-start;
  }

  @include vp-767 {
    flex-direction: row;
    align-items: center;
  }
}
.show__raiting-controls {
  margin-top: -3px;
}
.show__raiting-numbers {
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.14px;
}
.show__links {
  margin-left: auto;
  display: flex;
  align-items: center;

  @include vp-1919 {
    flex-direction: column;
    align-items: flex-end;
  }

  @include vp-767 {
    align-items: flex-start;
    margin: 36px 0 0;
  }
}
.show__link {
  border-radius: 12px;
  background: #FD4584;
  color: $color-default-white;
  display: flex;
  padding: 8px 30px;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  font-weight: 900;
  letter-spacing: 0.17px;
  text-transform: uppercase;
  transition: $trans-default;
  border: 2px solid $color-pink;

  @include hover-focus {
    background-color: $color-default-white;
    color: inherit;
  }

  &--light {
    background-color: $color-default-white;
    color: inherit;
    border: 2px solid $color-pink;

    @include hover-focus {
      background: #FD4584;
      color: $color-default-white;
    }
  }

  &:not(:last-child) {
    margin-right: 20px;
  }

  @include vp-1919 {
    &:not(:last-child) {
      margin: 0 0 10px;
    }
  }
}
.show__title {
  margin-top: 35px;
  margin-bottom: 7px;
}
.show__description {
  color: #828282;
  font-size: 18px;
  letter-spacing: -0.18px;
}
.show__description-more {
}
.show__description-more-btn {
  display: inline-flex;
  padding: 7px 22px 7px 23px;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  border: 2px solid #F2F2F2;
  background: #FAFAFA;
  color: var(--light-grey, #828282);
  text-align: center;
  font-size: 18px;
  letter-spacing: -0.18px;
  margin-top: 20px;

  span {
    &:last-child {
      display: none;
    }

    .is-active & {
      &:last-child {
        display: inline;
      }
      &:first-child {
        display: none;
      }
    }
  }
}
.show__slider-wrapper {
}
.show__slider-title {
  font-size: 40px;
  font-weight: 900;
  letter-spacing: -0.4px;
  text-align: center;
}
.show__slider {
  @include adaptiveDesktopToMobile('width', 595, -100);
  padding-bottom: 100px;

  .swiper-navigation__btn {
    position: absolute;
    top: 30%;
    z-index: 2;
    
    &--prev {
      left: 0;
    }
    &--next {
      right: 0;
    }
  }

  .swiper-pagination {
    bottom: 0;
  }
  .swiper-pagination-bullet {
    border-radius: 70px;
    background: #F5F5F5;
    width: 60px;
    height: 6px;
    opacity: 1;

    &-active {
      background: #FD4584;
    }
  }

  @include vp-1279 {
    width: 80vw;
  }

  @include vp-767 {
    padding-bottom: 50px;
  }
}
.show__slide {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;

  @include vp-767 {
    padding: 0;
  }
}
.show__slide-photo {
}
.show__slide-title {
  color: #FD4584;
  text-align: center;
  font-size: 30px;
  @include adaptiveDesktopToMobile('font-size', 30, 24);
  font-weight: 900;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  margin-top: 40px;
  margin-bottom: 10px;
}
.show__slide-description {
  color: #828282;
  text-align: center;
  font-size: 18px;
  letter-spacing: -0.18px;
}
.show__timetable {
  margin-top: 40px;
  border-radius: 30px;
  background: #FFE6FB;
  padding: 26px 44px 60px;

  @include vp-767 {
    padding-bottom: 20px;
  }
}
.show__timetable-title {
  color: #FD4584;
  margin: 0;
}
.show__timetable-list {
  @include reset-list;

  margin-top: 20px;
  display: flex;
  justify-content: space-between;

  @include vp-767 {
    flex-direction: column;
  }
}
.show__timetable-item {
  display: flex;
  align-items: center;

  @include vp-1365 {
    &:nth-child(6) {
      display: none;
    }
  }

  @include vp-1279 {
    &:nth-child(5) {
      display: none;
    }
    &:nth-child(4) {
      display: none;
    }
  }

  @include vp-767 {
    &:not(:last-child) {
      margin-bottom: 20px;
    }

    &:nth-child(3) {
      display: none;
    }
  }
}
.show__timetable-item-logo {
  margin-right: 15px;
}
.show__timetable-item-text {
}
.show__timetable-item-date {
  color: #FD4584;
  font-size: 20px;
  font-weight: 900;
  line-height: 100%;
  letter-spacing: -0.2px;
}
.show__timetable-item-time {
  color: #828282;
  font-size: 23px;
  font-weight: 700;
  letter-spacing: -0.23px;
}