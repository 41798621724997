.registration {
  @include adaptiveDesktopToMobile('padding-top', 50, 25);
  @include adaptiveDesktopToMobile('padding-bottom', 95, 25);
}
.registration__title {
  color: var(--pink, #FD4584);
  text-align: center;
  @include adaptiveDesktopToMobile('font-size', 35, 20);
  font-weight: 900;
  line-height: normal;
  letter-spacing: -0.35px;
  @include adaptiveDesktopToMobile('margin-bottom', 50, 30);
  margin-top: 0;
}
.registration__form {
  width: 100%;
}
.registration__form-links {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  @include vp-767 {
    flex-direction: column;
  }
}
.registration__form-link {
  color: var(--dark-gray, #333);
  font-size: 18px;
  font-weight: 700;
  letter-spacing: -0.18px;
  text-decoration-line: underline;

  @include vp-767 {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}
.registration__form-footer {
  *:not(:last-child) {
    margin-bottom: 30px;
  }

  @include vp-767 {
    .form__checkbox {
      label {
        &::before {
          width: 36px;
          height: 36px;
          border-radius: 10px;
        }
      }
    }
  }
}
.registration__form-info {
  @include adaptiveDesktopToMobile('font-size', 24, 18);
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.24px;
  max-width: 550px;
}